
.playlists-wrapper {
  background-color: transparent;
}
.player-header {
  background-color: #282c34;
  color: white;
  display: inline-block;
  width:100%;
  border-bottom:solid 1px #1d2126;
}
.playlists {
  display: block;
  margin:auto;
  padding-right:16px;
  padding-left:16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: white;
}
.playlist-img {
  height:80px;
  width:80px;
  float:left;
  content: '';
  margin-right:8px;
  background-image: url(https://server64.net/liststhumbs/default.jpg);
  background-position: center;
  background-size: 80px 80px;
}
.clear {
  clear: both;
}
.playlist {
  border:solid 1px #1d2126;
  text-align:left;
  margin:4px;
  background-color: #282c34;
  cursor: pointer;
}
.playlist .playlist-inner {
  border:solid 4px transparent;
  text-align:left;
  padding:6px;
}
.playlist.active .playlist-inner {
  border:solid 4px #14a1df;
}
.playlist-name {
  font-size: 14pt;
  font-weight: bold;
}
