.App {
  text-align: left;
  display: grid;
  height:100%;
  grid-template-rows: auto auto 1fr auto;
}
#root {
  height: 100%;
}
html, body {
  background-color: #20242a;
  color: white;
  height: 100%;
}
.header-inner {
  padding:16px;
}
button {
  border: none;
  border-radius: 30px;
  padding: 8px 16px;
  font-weight: bold;
}
.button-true {
  background-color:#14a1df;
}
.button-false {
  background-color:#6c777c;
}
.main-section {
  overflow-y: scroll;
}
.section-tabs {
  border-bottom:solid 1px #181b1e;
  position: relative;
  padding:8px 16px 0 16px;
  background-color: #282c34;
}
.section-tabs::after {
  content: " ";
  clear: both;
  display: block;
}
.tab {
  border-top:solid 1px #282c34;
  border-right:solid 1px #282c34;
  border-left:solid 1px #282c34;
  border-bottom:solid 1px #181b1e;
  display: block;
  padding:8px 16px;
  float:left;
  margin-bottom: -1px;
  background-color: #282c34;
  cursor: pointer;
  font-size:14pt;
}
.section-playlists .tab-playlists,
.section-artists .tab-artists,
.section-playing .tab-playing {
  border-top:solid 1px #181b1e;
  border-right:solid 1px #181b1e;
  border-left:solid 1px #181b1e;
  border-bottom:solid 1px #20242a !important;
  background-color: #20242a !important;
}
.playing-wrapper {
  margin:8px;
  border: solid 1px #2c3336;
  border-bottom: 0;
}
.artists {
  margin:8px;
  border: solid 1px #2c3336;
  border-bottom: 0;
}
.song-genre {
  float:right;
  opacity: .5;
}
.listitem {
  border-bottom: solid 1px #2c3336;
  background-color: #20242a;
  font-size: 16pt;
}
.listitem.active {
  color:#14a1df;
}
.order-handle {
  float:right;
}
.listitem-inner {
  display: flex;
}
.listitem-button {
  display: inline-block;
  padding:8px;
  margin-right:4px;
  cursor: pointer;
}
.listitem-name {
  padding:6px;
  flex-grow: 100;
}
.listitem-meta {
  padding:8px;
}
.listitem-meta .listitem-button {
  padding:0px;
}
.playing-wrapper > ul {
  padding:0;
  margin:0;
  list-style: none;
  list-style-type: none;
}
.playing-wrapper > ul > li {
  list-style: none;
  list-style-type: none;
}
