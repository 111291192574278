.player {
  width: 100%;
  height: 160px;
  bottom: 0;
  background-color: #282c34;
  color: white;
  border-top:solid 1px #1d2126;
}
.player-inner {
  padding:16px;
}
.player-title {
  font-size:16pt;
}
audio {

}
.player-table {
  width:100%;
  margin-top:16px;
}
.next-button {
  font-weight: 900;
  height: 54px;
  font-size: 14pt;
}
td {
  vertical-align: top;
}
